// Gatsby Requirements
import React, { useEffect } from 'react'
import { storyblokInit } from "gatsby-source-storyblok"

// Layout files
import Footer from './Footer/Footer'
import Header from './Header/Header'
import '../../styles/Global.scss'


storyblokInit({
    accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
});

const Layout = ({ children, headerData, footerData, slug, noFooterMargin }) => {

    // Datalayer click events
    useEffect(() => {
        function clickTrigger(i) {
            if (!i.hasAttribute("data-has-clickevent")) {
                i.setAttribute("data-has-clickevent", "");
                window?.dataLayer && window.dataLayer.push({
                    "gtm.elementText": i?.outerText,
                    "gtm.elementClasses": i?.classList?.toString(),
                    "gtm.element": i,
                    "gtm.elementId": i?.id,
                    "gtm.elementTarget": i.target,
                    "event": "gtm.click"
                })
            }
        }

        // Add function to body, so that it affects everything
        document.body.addEventListener('click', function(i) {
            clickTrigger(i.target)
        }, false);
    }, [])

    return (
        <>
            <Header headerData={headerData} />
                {children}
            <Footer noFooterMargin={noFooterMargin} slug={slug} footerData={footerData} />
        </>
    )
}

export default Layout