// Gatsby requirements
import React from "react"
import { Link, graphql } from 'gatsby'
 
// Components
import Seo from "../components/Layout/Seo"
import Layout from "../components/Layout/Layout"

// Styles
import * as CSS from "../styles/Sitemap.module.scss"

 
const Sitemap = ({ data }) => {

  return (
    <Layout footerData={data.footer} headerData={data.header}>

      <Seo
          title="Work ED – Sitemap"
          description="Work ED visual sitemap."
      />

        <main id="content" className={`wrapper ${CSS.page}`}>
            <h1>Work ED</h1>

            <h2>Pages</h2>
            <ul>
            {data?.pages?.edges?.map((loop, i) => (
                <li key={i}><Link to={'/' + (loop.node.full_slug === 'home' ? '' : loop.node.full_slug)}>{JSON.parse(loop.node.content).title.replaceAll(' – WorkED', '').replaceAll(' | Work ED', '').replaceAll('Work ED | ', '').replaceAll(' | WorkED', '')}</Link></li>
            ))}
            </ul>

            <h2>Blog Posts</h2>
            <ul>
            {data?.blogPosts?.edges?.map((loop, i) => (
                <li key={i}><Link to={'/'+loop.node.full_slug}>{JSON.parse(loop.node.content).title.replaceAll(' – WorkED', '').replaceAll(' | Work ED', '').replaceAll('Work ED | ', '').replaceAll(' | WorkED', '')}</Link></li>
            ))}
            </ul>

            <h2>Case Studies</h2>
            <ul>
            {data?.caseStudies?.edges?.map((loop, i) => (
                <li key={i}><Link to={'/'+loop.node.full_slug}>{JSON.parse(loop.node.content).title.replaceAll(' – WorkED', '').replaceAll(' | Work ED', '').replaceAll('Work ED | ', '').replaceAll(' | WorkED', '')}</Link></li>
            ))}
            </ul>

            <h2>Programs</h2>
            <details>
                <summary>View</summary>
                <ul>
                {data?.programs?.edges?.map((loop, i) => (
                    <li key={i}><Link to={'/'+loop.node.full_slug}>{JSON.parse(loop.node.content).title.replaceAll(' – WorkED', '').replaceAll(' | Work ED', '').replaceAll('Work ED | ', '').replaceAll(' | WorkED', '')}</Link></li>
                ))}
                </ul>
            </details>
        </main>

    </Layout>
  )
}

export const query = graphql`
  query {
    header: storyblokEntry(full_slug: { eq: "site-configuration/main-header" }) {
        content
    }
    footer: storyblokEntry(full_slug: { eq: "site-configuration/main-footer" }) {
        content
    }
    pages: allStoryblokEntry(filter: {field_component: {eq: "Page"}}) {
        edges {
            node {
                full_slug
                content
            }
        }
    }
    blogPosts: allStoryblokEntry(filter: {field_component: {eq: "Blog Post"}}, sort: {fields: first_published_at, order: DESC}) {
        edges {
            node {
                full_slug
                content
            }
        }
    }
    caseStudies: allStoryblokEntry(filter: {field_component: {eq: "Case Study"}}, sort: {fields: created_at, order: DESC}) {
        edges {
            node {
                full_slug
                content
            }
        }
    }

    programs: allStoryblokEntry(filter: {field_component: {eq: "Program"}}, sort: {fields: created_at, order: DESC}) {
        edges {
            node {
                full_slug
                content
            }
        }
    }
  }
`
 
export default Sitemap