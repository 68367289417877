// Gatsby requirements
import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'

// Components
import RichText from "utils/RichTextRenderer"
import Button from 'components/Button/Button'
import PopupButton from 'components/Button/PopupButton';

// Styles
import * as CSS from './Header.module.scss'


const Header = ({ headerData, previewData }) =>  {

    // Logic for opening the mobile header navigation
    const [isMenuOpen, setMenuOpen] = useState(false)

    const content = previewData?.content || JSON.parse(headerData.content);

    // Add sublist icons in case browser doesn't support :has()
    useEffect(() => {
        var nums = document.getElementById("navigation");
        var listItem = nums.getElementsByTagName("li");
        for (let item of listItem) {
            if (item.childElementCount > 1) {
                item.classList.add("has-sublist");
            }
        }
    }, [])
    
    return(
        <>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K9R8C3X" height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe></noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        <header className={`${CSS.header} ${isMenuOpen ? CSS.active : ''}`}>

            <a className={CSS.skip} href="#content">Skip to content</a>

            {content?.notificationText && !content?.hideNotificationText &&
                <div className={CSS.notification}>
                    <RichText data={content.notificationText} />
                </div>
            }

            <div className="wrapper">
                <Link to="/" aria-label="Link to homepage">
                    <img className="no-border-radius" src="/WorkED-logo.svg" width="121" height="31" loading="eager" alt="WorkED logo" />
                </Link>

                <button
                    aria-haspopup="true"
                    aria-label="Open main menu"
                    aria-controls="navigation"
                    aria-expanded={isMenuOpen ? "true" : "false"}
                    className={CSS.menuButton}
                    onClick={() => isMenuOpen ? setMenuOpen(false) : setMenuOpen(true)}
                >
                    <svg viewBox="0 0 100 80" fill="#000" width="30" height="25">
                        <rect y="10" width="100" rx="8" height="9"></rect>
                        <rect y="35" width="100" rx="8" height="9"></rect>
                        <rect y="60" width="100" rx="8" height="9"></rect>
                    </svg>
                </button>

                <nav id="navigation" className={CSS.nav}>
                    <RichText data={content.navigation} />
                    {content?.button && content.button.map((button, i) => {
                        if (button.component === 'Item: Popup Button') { return <PopupButton key={i} data={button} /> }
                        if (button.component === 'Item: Button') { return <Button key={i} insertClass="button" data={button} /> }
                    })}
                </nav>
            </div>

        </header>
        </>
    )
}

export default Header