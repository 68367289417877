// React requirements
import React from 'react'
import { Script } from "gatsby"

const Seo = ( data ) => {

    const url = data?.url?.endsWith('/') ? data?.url : data?.url + '/'

    return (
        <>
        <meta property="og:locale" content="en-US" />

        <title>{data.title ? data.title : 'WorkED | Work Based Learning'}</title>
        <meta name="title" content={data.title ? data.title : 'WorkED | Work Based Learning'} />
        <meta property="og:title" content={data.title ? data.title : 'WorkED | Work Based Learning'} />
        <meta property="twitter:title" content={data.title ? data.title : 'WorkED | Work Based Learning'} />

        <meta property="og:site_name" content="WorkED" />
        <meta property="twitter:card" content="summary_large_image" />

        {data.url && <meta property="og:url" content={`https://worked.com${url}`} /> }
        {data.url && <meta property="twitter:url" content={`https://worked.com${url}`} /> }
        {data.url && <link rel="canonical" href={`https://worked.com${url}`} /> }

        {data.type && <meta property="og:type" content={data.type} /> }

        <meta property="og:image" content="https://worked.com/worked-thumbnail.png" />
        <meta property="twitter:image" content="https://worked.com/worked-thumbnail.png" />

        <meta name="description" content={data.description ? data.description : 'We create opportunities for students to work on real work simulations in teams along with professionals from growing industries.'} />
        <meta name="og:description" content={data.description ? data.description : 'We create opportunities for students to work on real work simulations in teams along with professionals from growing industries.'} />
        <meta name="twitter:description" content={data.description ? data.description : 'We create opportunities for students to work on real work simulations in teams along with professionals from growing industries.'} />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2ea363" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        {data?.noindex && <meta name="robots" content="noindex" /> }

        <Script strategy="idle" id="gtm">
            {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-K9R8C3X');
            `}
        </Script>

        </>
    )
}

export default Seo